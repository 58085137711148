@font-face {
  font-family: "DIN 2014";
  src: url("./css/fonts/DIN2014-Regular.woff2") format("woff2"),
    url("./css/fonts/DIN2014-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DIN-2014-Bold";
  src: url("./css/fonts/DIN2014-ExtraBold.woff") format("woff2");
}

@font-face {
  font-family: "DIN-2014-Light";
  src: url("./css/fonts/DIN2014-Light.woff") format("woff2");
}

html body {
  font-family: "DIN 2014";
}

.google-visualization-tooltip {
  border: solid 1px #292a2c !important;
  background-color: #292a2c !important;
  width: auto !important;
  height: 25px !important;
  display: flex;
  align-items: center;
  color: #fff;
}
.cutom-tooltip ul {
  padding: 0px 6px;
  list-style-type: none;
  min-width: 96px;
}
.cutom-tooltip ul li {
  position: relative;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
}
.cutom-tooltip ul li::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background: #a5d8ff;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translate(0px, -5px);
}
.drawerCloseButton {
  color: #fff;
  position: relative;
  z-index: 2222;
  top: 24px;
  position: absolute;
  right: 16px;
  display: inline-block;
}
.drawerCloseButton:hover {
  background-color: transparent;
}
.drawerCloseButton svg {
  width: 24px;
  height: 24px;
}
.mantine-Table-root tbody tr:last-of-type td {
  border-bottom: 1px solid #dee2e6;
}
.mantine-Select-rightSection svg {
  color: #292a2c !important;
  width: 24px;
  height: 24px;
}

.zoom-options-wrapper {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #a1a1a1;
  min-width: 134px;
  justify-content: center;
}
.zoom-options {
  width: 40px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 31px;
  line-height: 29px;
  padding: 9px 0px 4px;
  cursor: pointer;
}
.zoom-options.percentage {
  font-size: 16px;
  cursor: inherit;
}

@media screen and (min-width: 769px) {
  .appshell-main {
    padding-top: 0px !important;
    padding-right: 0px;
    padding-left: 200px;
  }
}
@media screen and (min-width: 1201px) {
  .appshell-main {
    padding-left: 300px;
  }
}

.pac-item {
  padding: 10px 15px;
}
.pac-logo::after {
  display: none;
}
.pac-item-query {
  font-size: 12px;
  font-weight: 700;
}
.gmnoprint[role="menubar"] {
  left: 255px !important;
}
.gm-bundled-control-on-bottom {
  right: 171px !important;
}
.gm-bundled-control-on-bottom .gmnoprint > div {
  display: flex;
  width: auto !important;
  height: auto !important;
}
.gmnoprint[role="menubar"] button {
  height: 40px;
  width: 42px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  top: 15px !important;
  box-shadow: none !important;
}
/* .mantine-Modal-modal{
  font-family: verdana;
} */
.mantine-Modal-header {
  padding: 20px 20px 18px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 20px;
  width: 100%;
}
.mantine-Modal-body {
  padding: 0px 20px 20px;
}
.mantine-Modal-title {
  font-size: 22px;
  color: #777777;
  font-weight: bold;
}
.mantine-Modal-close svg {
  width: 24px;
  height: 24px;
}
.primary-button {
  background-color: #ff8e16;
  border-color: #ff8e16;
}
.primary-button:hover {
  background-color: #fd7e14;
}

.outlined-button {
  border-color: #ff8e16;
  color: #ff8e16;
}
.outlined-button:hover {
  background-color: #ff8e16;
  color: #fff;
}

.outlined-button-add-address {
  border-color: #23cf20;
  color: #23cf20;
  width: 36px;
  height: 36px;
}
.outlined-button-add-address:hover {
  background-color: #23cf20;
  color: #fff;
}

.outlined-button-delete-input {
  border-color: #f90000;
  color: #f90000;
  width: 36px;
  height: 36px;
}
.outlined-button-delete-input:hover {
  background-color: #f90000;
  color: #fff;
}

.mantine-RadioGroup-radio:checked {
  background: #ff8e16;
  border-color: #ff8e16;
}

.gm-style-iw {
  padding: 0px !important;
}
.gm-style-iw-d {
  padding: 0px !important;
  display: contents;
}

.gm-style .gm-style-iw-t::after,
.gm-ui-hover-effect {
  display: none !important;
}

.fileWrapper {
  text-align: left;
  text-transform: capitalize;
}
.fileWrapper svg {
  width: 40px;
}

.pac-target-input {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 40px;
  padding: 0 12px;
  border-radius: 3px;
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  /* position: relative;
  left: 20px;
  top: 20px; */
  border-right: 1px solid #ccc;
}
.customDrawer {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #414042;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.control-main-div {
  position: relative;
  left: 20px !important;
  top: 20px !important;
  display: flex;
}
.gm-ui-hover-effect {
  display: block !important;
}
.btn-wrapper {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.btn-wrapper button {
  background: none;
  border: none;
}
#marker-btn img {
  width: 28px !important;
  height: 28px !important;
}
.addresses-data {
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
}
/* #circle-btn:after {
  background-image: url('../images/logo.png');
  background-size: 10px 20px;
  display: inline-block;
  width: 10px; 
  height: 20px;
  content:"";
} */

.modal-ano {
  width: 307px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);
  background: #fff;
}

.ma-body {
  padding: 19px 14px 28px;
}

.ma-user {
  display: flex;
}

.ma-user-info {
  padding-left: 10px;
  text-align: left;
}

.username {
  color: #414042;
  font-size: 16px;
  line-height: 17px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0;
}

.comment-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #292a2c;
  margin: 0;
  word-break: break-word;
  padding-right: 8px;
}

.ma-form {
  display: flex;
  padding: 0 14px 15px;
}
.comment-input {
  padding: 7px 16px;
  border: 1px solid #bcbec0;
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #292a2c;
  flex: 1;
}

.comment-btn {
  padding: 4px 14px;
  border: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #ff8e16;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

div[role="dialog"] button[title="Close"] {
  right: 3px !important;
  top: -3px !important;
  width: 35px !important;
  height: 35px !important;
}

div[role="dialog"] button[title="Close"] > span {
  width: 22px !important;
  height: 22px !important;
}
.ai-chat-input {
  min-width: 200px;
}
.ai-chat-box {
  max-height: 300px !important;
  overflow-y: auto;
  display: block !important;
}
.comments-box {
  max-height: 100px;
  overflow-y: auto;
}
.comments-box::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.comments-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.comments-box::-webkit-scrollbar-thumb {
  background: #ff8e16;
}

/* Handle on hover */
.comments-box::-webkit-scrollbar-thumb:hover {
  background: #fd7e14;
}
.comment-info:last-child {
  margin-bottom: 0 !important;
}
.annotation-hr {
  border: none;
  border-top: 2px solid #e3e3e3;
  margin-bottom: 10px;
}
.annotation-time {
  display: block;
  color: #777777;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  padding-bottom: 8px;
}
.comment-info {
  display: flex;
  border: 2px solid #e3e3e3;
  padding: 4px;
  border-radius: 12px;
  margin-bottom: 10px;
  margin-right: 4px;
}

.actions-modal {
  width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);
  background: #fff;
}
.action-modal-inner {
  display: flex;
  flex-direction: column;
  padding: 12px 0px 10px;
}
.action-btn {
  padding: 6px;
  width: 100%;
  border: 0;
  border-radius: 0px;
  background: transparent;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 7px;
  transition: 0.2s all ease-in-out;
}
.action-btn svg {
  width: 23px;
  height: 23px;
}
.action-btn:hover {
  background: #f1f1f1;
  cursor: pointer;
}
div[aria-label="polygon-options"] button[title="Close"],
div[aria-label="sa1s-infowindow"] button[title="Close"] {
  display: none !important;
}
/* div[aria-label="polygon-options"] button[title="Close"] > span {
  width: 17px !important;
  height: 17px !important;
} */
div[aria-label="polygon-options"] + .gm-style-iw-tc {
  display: none !important;
}
.gm-style-iw {
  max-height: 300px !important;
}
.gm-style-mtc button {
  width: 100%;
}
@media screen and (max-width: 769px) {
  .gm-style-mtc {
    top: 40px !important;
  }
  .gm-bundled-control {
    top: 40px !important;
  }
  .gm-bundled-control-on-bottom {
    right: 120px !important;
    left: auto !important;
    top: auto !important;
    bottom: 60px !important;
  }
  .mantine-1gjydfj {
    height: 30px;
    min-width: 70px;
  }
}
div[aria-label="sa1s-infowindow"] {
  border-radius: 0 !important;
  border: 1px solid #000;
}
div[aria-label="sa1s-infowindow"] + div.gm-style-iw-tc {
  display: none !important;
}
.value-p {
  margin: 3px 6px;
  font-size: 14px;
}

.envelope {
  font-family: Arial, sans-serif; /* Specify a font that supports the envelope symbol */
}