/* .logo-wrapper {
    margin: 0 auto;
}
.links-wrapper {
    margin: 0 auto;
    max-width: 180px;
    width: 100%;
} */
.maps-title {
  max-width: 185px;
  width: 100%;
  text-align: left;
  padding-left: 14px;
  margin-bottom: 8px;
}

.user {
  display: block;
  width: 100%;
  color: #ffffff;
}

.user:hover {
  background-color: #3d3c3e;
  color: white;
}
/* .user-menu {
  background-color: #414042;
  border-color: #414042;
  right: -40px;
} */
.title-row{
  width: calc(100% - -60px);
  margin-inline:-30px;
  padding: 0 20px;
}
.menu-item:hover {
  background-color: #414042;
  color: #ffffff;
}
.title-col{
  display: flex;
  align-items: center;
}
.profile-setting{
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}
.profile-setting .mantine-Avatar-root{
  border: 1px solid #3d3c3e;
}
.profile-setting .mantine-Menu-root button{
  padding: 0;
  background-color: transparent;
}
.profile-setting .mantine-Menu-root .mantine-gnzaph{
  color: #34353A;
}
.profile-setting .mantine-Menu-root {
  fill: transparent;
  color: #34353A;
}
.subTitle{
  padding: 25px 0px 20px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .title-row{
  flex-direction: column-reverse;
  margin-top: 0;
  }
}