.signup-wrap {
    min-height: 100vh;
    background-color: #cccc;
}
.main-container {
    max-width: 1440px;
}
.signup-left {
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;    
}
.signup-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.signup-img {
    height: 100%;
    position: relative;
}
.signup-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 255px;
    width: 100%;
}
.anchor-text {
    color: orange;
    margin: auto;
}
.welcome-text {
    margin: 0 auto;
    max-width: 245px;
    width: 100%;
    font-size: 16px;
}
.main-box {
    max-width: 320px;
    width: 100%;
}
.main-box-error {
    max-width: 480px;
    width: 100%;
}
.loc-img{
    position: absolute;
}
.loc-1 {
    left: 11%;
    top: 15%;
}

.loc-2 {
    right: 1%;
    top: 65%;
}

.loc-3 {
    left: 11%;
    bottom:  5%;
}
.loc-img img{
    max-width: 100px;
}
/* placeholder styles */
::placeholder { 
    font-weight: bold;
}