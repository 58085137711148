.main-container {
  max-width: 1440px;
}
.login-wrap {
  min-height: 100vh;
  background-color: #cccc;
  padding: 0 8px;
}
.log-left {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-pas {
  min-height: 90vh;
  overflow: hidden;
}
.log-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.google-btn {
  width: 100%;
  padding: 0 8px;
}
.log-img {
  height: 100%;
  position: relative;
}
.log-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.anchor-text {
  color: orange;
  margin: auto;
  font-size: 14px;
}
.welcome-text {
  margin: 0 auto;
  max-width: 273px;
  width: 100%;
  color: #222b45;
  font-size: 16px;
  line-height: 24px;
}
.login-text {
  color: #292a2c;
  font-family: "DIN 2014";
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 39px;
  margin-bottom: 32px;
}
.main-box {
  max-width: 300px;
  width: 100%;
}

.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 255px;
  width: 100%;
}
.text-box {
  position: absolute;
  left: 57%;
  top: calc(50% + 150px);
  transform: translate(-50%, -50%);
  max-width: 237px;
  width: 100%;
}
.text {
  font-size: 36px;
  font-weight: 800;
  line-height: 41px;
  text-align: start;
}

.log-info {
  padding: 24px 34px;
  background: linear-gradient(180deg, #ff5204 0%, #ffa801 100%);
  text-align: left;
}
.log-info-row {
  display: flex;
  align-items: flex-start;
}
.log-avatar {
  box-sizing: border-box;
  height: 84px;
  width: 84px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 17px;
}
.log-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.info-text {
  padding-left: 48px;
  position: relative;
  /* max-width: 350px; */
  max-width: 390px;
}
.log-info p {
  color: #ffffff;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: 2px;
}
.log-info .quote {
  color: #ffffff;
  font-size: 59px;
  letter-spacing: 0;
  line-height: 95px;
  position: absolute;
  left: 27px;
  top: -3px;
}
.log-info p + span {
  color: #292a2c;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.loc-img {
  position: absolute;
}
.loc-1 {
  left: 11%;
  top: 15%;
}

.loc-2 {
  right: 1%;
  top: 65%;
}

.loc-3 {
  left: 11%;
  bottom: 5%;
}
.loc-img img {
  max-width: 100px;
}

@media screen and (max-width: 1199px) {
  .log-info-row {
    flex-wrap: wrap;
  }
  .log-info .quote {
    left: 0;
    top: 6px;
  }
  .info-text {
    padding-left: 27px;
  }
}

@media screen and (max-width: 768px) {
  .log-left {
    min-height: 77vh;
  }
}
